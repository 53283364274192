import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { ProcessStatus } from '@app/operations/process-status';
import { ServiceResult } from '@app/shared/service-result';
import { SourcePackage } from '@app/workflow/source-package';
import { SubscriptionStatus } from '@app/operations/subscription-status';

@Injectable()
export class WorkflowService {
    private readonly workflowUrl = 'api/workflow/';

    constructor(private http: HttpClient) { }

    exportSourceConfig(sourceId: string, exportAll: boolean = false, environment: string = null) {
        const headers = new HttpHeaders();
        headers.set('Accept', 'application/octet-stream');

        // Initialize Params Object
        let params = new HttpParams().set('exportAll', exportAll.toString());

        params = environment ? params.set('environment', environment) : params;

        return this.http.get(this.workflowUrl + 'exportsource/' + sourceId,
            {
                params,
                headers,
                responseType: 'blob',
                observe: 'response'
            });
    }

    getSourceStatus(sourceId?: string): Observable<ServiceResult<ProcessStatus>> {
        if (sourceId == null) {
            sourceId = '';
        }

        return this.http.get<ServiceResult<ProcessStatus>>(this.workflowUrl + 'status/' + sourceId);
    }

    getSubscriptionStatus(intervalInDays?: number): Observable<ServiceResult<SubscriptionStatus>> {
        // Initialize Params Object
        let params = new HttpParams();

        params = intervalInDays ? params.append('days', intervalInDays.toString()) : params;

        return this.http.get<ServiceResult<SubscriptionStatus>>(this.workflowUrl + 'subscriptionstatus', { params });
    }

    importSourceConfig(sourceId: string, data: FormData): Observable<ServiceResult<SourcePackage>> {
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/form-data');

        return this.http.post<ServiceResult<SourcePackage>>(this.workflowUrl + 'importsource/' + sourceId,
            data,
            {
                headers,
                reportProgress: true
            });
    }

    publishSourceConfig(sourceId: string, targetEnvironment: string, sourceEnvironment: string = null):
        Observable<ServiceResult<SourcePackage>> {
        // Initialize Params Object
        let params = new HttpParams();

        params = sourceEnvironment ? params.append('sourceEnvironment', sourceEnvironment) : params;

        return this.http.post<ServiceResult<SourcePackage>>(this.workflowUrl +
            'publishsource/' +
            sourceId +
            '/target/' +
            targetEnvironment,
            null,
            {
                params
            });
    }
}
